import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import SecondaryHero from "../../components/secondary-hero";

const SpecialProjectPage = ({ transitionStatus }) => {
	const data = useStaticQuery(graphql`
		query {
			heroImgMain: wpMediaItem(title: { eq: "Home Hero Main" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			wpPageContent(id: { eq: "cG9zdDoyMDg5" }) {
				content
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Fundraising",
				item: {
					url: `${siteUrl}/fundraising`,
					id: `${siteUrl}/fundraising`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "2025 Special Project",
				item: {
					url: `${siteUrl}/fundraising/2025-special-project`,
					id: `${siteUrl}/fundraising/2025-special-project`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="2025 Special Project | Friends of Eastbourne Hospital"
				description="The Friends of Eastbourne Hospital have agreed to purchase a gamma camera for nuclear medicine as their Major Project of 2025 and will be fundraising for £500K."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/fundraising/2025-special-project`,
					title: "2025 Special Project | Friends of Eastbourne Hospital",
					description:
						"The Friends of Eastbourne Hospital have agreed to purchase a gamma camera for nuclear medicine as their Major Project of 2025 and will be fundraising for £500K.",
					images: [
						{
							url: `${data.heroImgMain?.localFile.publicURL}`,
							width: `${data.heroImgMain?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImgMain?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImgMain?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section className="pb-5">
					<SecondaryHero
						title="2025 special project"
						description={
							<span>The Friends of Eastbourne Hospital have agreed a donation of
							<br class="d-none d-md-block" /> £1 million to the new Sussex Surgical Centre and Endoscopy
							<br class="d-none d-md-block" />Development at the DGH. In order to complete this, our Major
							<br class="d-none d-md-block" /> Project for 2025 is to fundraise   for the final £250k.
							</span>
						}
					/>
				</section>
				<section className="pb-md-7 pb-5">
					<Container>
						<Row>
							<Col className="page-content special-project">
								{data.wpPageContent ? parse(data.wpPageContent?.content) : ""}
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default SpecialProjectPage;
