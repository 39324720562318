import React, { useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import HowWeHelpCard from "./how-we-help-card";
import useOnScreen from "./use-on-screen";
import gsap from "gsap";

const HowWeHelp = () => {
  const ref1 = useRef();
  const onScreen1 = useOnScreen(ref1, "0px");

  useEffect(() => {
    if (onScreen1 === true) {
      gsap.to(".main-card-left", {
        y: 0,
        duration: 1,
        delay: 0.2,
      });
      gsap.to(".main-card-middle", {
        y: 0,
        duration: 1,
        delay: 1,
      });
      gsap.to(".main-card-right", {
        y: 0,
        duration: 2,
        delay: 2,
      });
    }
  }, [onScreen1]);
  const data = useStaticQuery(graphql`
    query {
      LogoImg: wpMediaItem(title: { eq: "icon-no-bg" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 50
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: TRACED_SVG
            )
          }
        }
      }
      eImg: wpMediaItem(title: { eq: "HP2 Equipment Amenities Services" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 50
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      spImg: wpMediaItem(title: { eq: "Major Project" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 50
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      pspImg: wpMediaItem(title: { eq: "HP4 Previous Special Projects" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 50
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
    }
  `);

  return (
    <Container>
      <Row ref={ref1} className="justify-content-between  align-items-center ">
        <Col md={6}>
          <h2 className="display-4 text-center text-md-start text-primary">
            How we help the Hospital
          </h2>
          <p>
            From a simple microwave for a ward whose staff want to heat up
            home-made food on their lunch break to a million-pound investment in
            a surgical robot to keep the DGH at the forefront of prostate cancer
            treatment, the Friends will consider and consequently fundraise for
            any equipment, amenity or service that the hospital, its staff and
            consequently its patients, need.
          </p>
        </Col>
        <Col className=" text-center my-4 my-md-0 text-md-end" md={4}>
          {" "}
          <GatsbyImage
            image={data.LogoImg?.localFile?.childImageSharp.gatsbyImageData}
            alt={data.LogoImg?.altText}
            style={{ maxWidth: "200px" }}
          />
        </Col>
      </Row>
      <Row style={{ overflow: "hidden" }} className="gx-lg-6 pt-5 pt-lg-7 ">
        <HowWeHelpCard
          animationClass="main-card-left"
          title={
            <span>
              EQUIPMENT, AMENITIES &<br /> SERVICES
            </span>
          }
          image={data.eImg?.localFile?.childImageSharp.gatsbyImageData}
          imageAlt={data.eImg?.altText}
          text="As well as fundraising for one major project each year, the Friends provide funds in response to bids for equipment, amenities and services for patients, staff and visitors."
          url="/fundraising/equipment-amenities-services"
        />

        <HowWeHelpCard
          animationClass="main-card-middle"
          title={
            <span>
              2025
              <br />
              SPECIAL PROJECT
            </span>
          }
          image={data.spImg?.localFile?.childImageSharp.gatsbyImageData}
          imageAlt={data.spImg?.altText}
          text="The Friends of Eastbourne Hospital have agreed a donation of £1 million to the new Sussex Surgical Centre and Endoscopy Development at the DGH. In order to complete this, our Major Project for 2025 is to fundraise for the final £250k."
          url="/fundraising/2025-special-project"
        />
        <HowWeHelpCard
          animationClass="main-card-right"
          title={
            <span>
              PREVIOUS
              <br />
              SPECIAL PROJECTS
            </span>
          }
          image={data.pspImg?.localFile?.childImageSharp.gatsbyImageData}
          imageAlt={data.pspImg?.altText}
          text="Every year for over two decades we have agreed a special project for that year, and have reached every fundraising target, even during the challenging years of the pandemic."
          url="/fundraising/previous-projects"
        />
      </Row>
    </Container>
  );
};

export default HowWeHelp;
