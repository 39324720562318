exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-aims-and-objectives-js": () => import("./../../../src/pages/about-us/aims-and-objectives.js" /* webpackChunkName: "component---src-pages-about-us-aims-and-objectives-js" */),
  "component---src-pages-about-us-annual-general-meeting-js": () => import("./../../../src/pages/about-us/annual-general-meeting.js" /* webpackChunkName: "component---src-pages-about-us-annual-general-meeting-js" */),
  "component---src-pages-about-us-executive-committee-js": () => import("./../../../src/pages/about-us/executive-committee.js" /* webpackChunkName: "component---src-pages-about-us-executive-committee-js" */),
  "component---src-pages-about-us-our-funding-js": () => import("./../../../src/pages/about-us/our-funding.js" /* webpackChunkName: "component---src-pages-about-us-our-funding-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-fundraising-2025-special-project-js": () => import("./../../../src/pages/fundraising/2025-special-project.js" /* webpackChunkName: "component---src-pages-fundraising-2025-special-project-js" */),
  "component---src-pages-fundraising-equipment-amenities-services-js": () => import("./../../../src/pages/fundraising/equipment-amenities-services.js" /* webpackChunkName: "component---src-pages-fundraising-equipment-amenities-services-js" */),
  "component---src-pages-fundraising-previous-projects-js": () => import("./../../../src/pages/fundraising/previous-projects.js" /* webpackChunkName: "component---src-pages-fundraising-previous-projects-js" */),
  "component---src-pages-hospitality-magazine-js": () => import("./../../../src/pages/hospitality-magazine.js" /* webpackChunkName: "component---src-pages-hospitality-magazine-js" */),
  "component---src-pages-how-can-i-help-donations-js": () => import("./../../../src/pages/how-can-i-help/donations.js" /* webpackChunkName: "component---src-pages-how-can-i-help-donations-js" */),
  "component---src-pages-how-can-i-help-legacies-js": () => import("./../../../src/pages/how-can-i-help/legacies.js" /* webpackChunkName: "component---src-pages-how-can-i-help-legacies-js" */),
  "component---src-pages-how-can-i-help-we-collect-js": () => import("./../../../src/pages/how-can-i-help/we-collect.js" /* webpackChunkName: "component---src-pages-how-can-i-help-we-collect-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-membership-corporate-membership-js": () => import("./../../../src/pages/membership/corporate-membership.js" /* webpackChunkName: "component---src-pages-membership-corporate-membership-js" */),
  "component---src-pages-membership-individual-membership-js": () => import("./../../../src/pages/membership/individual-membership.js" /* webpackChunkName: "component---src-pages-membership-individual-membership-js" */),
  "component---src-pages-membership-our-corporate-supporters-js": () => import("./../../../src/pages/membership/our-corporate-supporters.js" /* webpackChunkName: "component---src-pages-membership-our-corporate-supporters-js" */),
  "component---src-pages-news-blog-js": () => import("./../../../src/pages/news/blog.js" /* webpackChunkName: "component---src-pages-news-blog-js" */),
  "component---src-pages-news-latest-news-js": () => import("./../../../src/pages/news/latest-news.js" /* webpackChunkName: "component---src-pages-news-latest-news-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-volunteering-becoming-a-volunteer-js": () => import("./../../../src/pages/volunteering/becoming-a-volunteer.js" /* webpackChunkName: "component---src-pages-volunteering-becoming-a-volunteer-js" */),
  "component---src-pages-volunteering-events-js": () => import("./../../../src/pages/volunteering/events.js" /* webpackChunkName: "component---src-pages-volunteering-events-js" */),
  "component---src-pages-volunteering-the-benefits-of-volunteering-js": () => import("./../../../src/pages/volunteering/the-benefits-of-volunteering.js" /* webpackChunkName: "component---src-pages-volunteering-the-benefits-of-volunteering-js" */),
  "component---src-pages-volunteering-volunteering-opportunities-js": () => import("./../../../src/pages/volunteering/volunteering-opportunities.js" /* webpackChunkName: "component---src-pages-volunteering-volunteering-opportunities-js" */),
  "component---src-pages-volunteering-volunteering-stories-js": () => import("./../../../src/pages/volunteering/volunteering-stories.js" /* webpackChunkName: "component---src-pages-volunteering-volunteering-stories-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-custom-page-js": () => import("./../../../src/templates/custom-page.js" /* webpackChunkName: "component---src-templates-custom-page-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-shop-js": () => import("./../../../src/templates/shop.js" /* webpackChunkName: "component---src-templates-shop-js" */),
  "component---src-templates-volunteer-js": () => import("./../../../src/templates/volunteer.js" /* webpackChunkName: "component---src-templates-volunteer-js" */)
}

